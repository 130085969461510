@import '../../@framework/components/responsivetable/responsivetable.component.less';
.Layout-global-variables() {
                  .global-variables();
                
                }

                .zapp-Layout  {
                  .Layout-global-variables();
                  
                  
                  
                  .Layout-status-mixin();
}













.Row-global-variables() {
                  .global-variables();
                  @PaddingBottom: 0.25rem;
  @PaddingLeft: 1rem;
  @PaddingRight: 1rem;
  @PaddingTop: 0.25rem;

                }

                .zapp-Row  {
                  .Row-global-variables();
                  
                  
                  
                  .Row-status-mixin();
}













.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}
.Column-global-variables() {
                  .global-variables();
                  @PaddingLeft: 0.25rem;
  @PaddingRight: 0.25rem;

                }

                .zapp-Column  {
                  .Column-global-variables();
                  
                  
                  
                  .Column-status-mixin();
}













