@import '../../@framework/components/datalist/datalist.component.less';

.list-global-variables() {
                  .global-variables();
                  @ActionsDropDownBorderColor: @header-bg;
  @ActionsDropDownColor: @header-bg;
  @ActionsDropDownHoverBackgroundColor: @header-bg;
  @ActionsDropDownHoverBorderColor: @header-bg;
  @ActionsDropDownHoverColor: @header-color;
  @BodyBorderColor: @border-color;
  @CellBorderColorBottom: @border-color;
  @CellBorderColorLeft: @border-color;
  @CellBorderColorRight: @border-color;
  @CellBorderColorTop: @border-color;
  @CellFontSize: @font-med;
  @FooterBackgroundColor: @footer-bg;
  @FooterColor: @footer-color;
  @GlobalSearchBorderBottomColor: @header-bg;
  @GlobalSearchBorderLeftColor: @header-bg;
  @GlobalSearchBorderRightColor: @header-bg;
  @GlobalSearchBorderTopColor: @header-bg;
  @GlobalSearchColor: @header-bg;
  @GlobalSearchIconsColor: @header-bg;
  @GlobalSearchIconsHoverBackgroundColor: @header-bg;
  @GlobalSearchIconsHoverColor: @header-color;
  @HeaderBackgroundColor: @header-bg;
  @HeaderColor: @header-color;
  @HeaderFontSize: @font-med;
  @PagerActiveColor: @color-primary;
  @PagerBorderRadius: @default-radius;
  @PagerDisabledColor: @font-color;
  @PagerHoverBackgroundColor: @header-bg;
  @PagerHoverColor: @header-color;
  @QuickFilterIconsBackgroundColor: @input-bg;
  @QuickFilterIconsColor: @input-color;
  @QuickFilterIconsHoverBackgroundColor: @input-bg;
  @QuickFilterIconsHoverColor: @input-color;
  @QuickFiltersBackgroundColor: @input-bg;
  @QuickFiltersBorderColor: @input-border-color;
  @QuickFiltersColor: @input-color;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @row-hover-bg;
  @RowHoverColor: @row-hover-color;
  @RowNumberColor: @row-color;
  @RowSelectedBackground: @color-primary;
  @Shadow: @default-shadow;
  @ToolbarButtonsBorderColor: @header-bg;
  @ToolbarButtonsColor: @header-bg;
  @ToolbarButtonsHoverBackgroundColor: @color-default-hover;
  @ToolbarButtonsHoverBorderColor: @header-bg;
  @ToolbarButtonsHoverColor: @header-color;
  @ToolbarButtonsRadius: @default-radius;
  @ActionsDropDownBackgroundColor: transparent;
  @BodyBorderStyle: none;
  @BodyBorderWidth: 1px;
  @BorderBottomStyle: none;
  @BorderBottomWidth: 0px;
  @BorderCollapse: separate;
  @BorderLeftStyle: none;
  @BorderLeftWidth: 0px;
  @BorderRightStyle: none;
  @BorderRightWidth: 0px;
  @BorderSpacing: 3px 3px;
  @BorderTopStyle: none;
  @BorderTopWidth: 0px;
  @CellBorderStyleBottom: solid;
  @CellBorderStyleLeft: solid;
  @CellBorderStyleRight: solid;
  @CellBorderStyleTop: solid;
  @CellBorderWidthBottom: 1px;
  @CellBorderWidthLeft: 1px;
  @CellBorderWidthRight: 1px;
  @CellBorderWidthTop: 1px;
  @CellHeight: 3rem;
  @GlobalSearchBackgroundColor: transparent;
  @GlobalSearchIconsBackgroundColor: transparent;
  @HeaderFontWeight: bold;
  @HeaderHoverTextDecoration: underline;
  @HeaderPaddingBottom: 15px;
  @HeaderPaddingTop: 15px;
  @LoaderColor: #4d4f5c;
  @PagerActiveBackgroundColor: transparent;
  @PagerActiveBorderColor: transparent;
  @PagerAlign: center;
  @PagerBackgroundColor: transparent;
  @PagerBorderWidth: 1px;
  @PagerDisabledBackgroundColor: transparent;
  @PagerDisabledBorderColor: transparent;
  @PagerMargin: 3px;
  @RowNumberFontWeight: bold;
  @RowSelectedColor: white;
  @ToolbarButtonsBackgroundColor: transparent;
  @ToolbarButtonsMargin: 3px;

                }

                .zapp-list  {
                  .list-global-variables();
                  
                  
                  
                  .list-status-mixin();
}

.list-status-default {
  .global-variables();



  .list-status-mixin();
}

.list-status-primary {
  .global-variables();



  .list-status-mixin();
}

.list-status-info {
  .global-variables();



  .list-status-mixin();
}

.list-status-success {
  .global-variables();



  .list-status-mixin();
}

.list-status-warning {
  .global-variables();



  .list-status-mixin();
}

.list-status-danger {
  .global-variables();



  .list-status-mixin();
}







