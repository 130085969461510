@import '../../@framework/components/hyperlink/hyperlink.component.less';
.hyperlink-global-variables() {
                  .global-variables();
                  @ForegroundColor: @font-color;
  @HoverColor: @font-color;

                }

                .zapp-hyperlink  {
                  .hyperlink-global-variables();
                  
                  
                  
                  .hyperlink-status-mixin();
}

.hyperlink-status-default {
  .global-variables();
  @ForegroundColor: @color-default;
  @HoverColor: @color-default-hover;



  .hyperlink-status-mixin();
}

.hyperlink-status-primary {
  .global-variables();
  @ForegroundColor: @color-primary;
  @HoverColor: @color-primary-hover;



  .hyperlink-status-mixin();
}

.hyperlink-status-info {
  .global-variables();
  @ForegroundColor: @color-info;
  @HoverColor: @color-info-hover;



  .hyperlink-status-mixin();
}

.hyperlink-status-success {
  .global-variables();
  @ForegroundColor: @color-success;
  @HoverColor: @color-success-hover;



  .hyperlink-status-mixin();
}

.hyperlink-status-warning {
  .global-variables();
  @ForegroundColor: @color-warning;
  @HoverColor: @color-warning-hover;



  .hyperlink-status-mixin();
}

.hyperlink-status-danger {
  .global-variables();
  @ForegroundColor: @color-danger;
  @HoverColor: @color-danger-hover;



  .hyperlink-status-mixin();
}

.hyperlink-size-xs {
  .global-variables();
  @FontSize: @font-xs;



  .hyperlink-size-mixin();
}

.hyperlink-size-sm {
  .global-variables();
  @FontSize: @font-sm;



  .hyperlink-size-mixin();
}

.hyperlink-size-med {
  .global-variables();
  @FontSize: @font-med;



  .hyperlink-size-mixin();
}

.hyperlink-size-lg {
  .global-variables();
  @FontSize: @font-lg;



  .hyperlink-size-mixin();
}

.hyperlink-size-xl {
  .global-variables();
  @FontSize: @font-xl;



  .hyperlink-size-mixin();
}


