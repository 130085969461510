@import '../../@framework/components/fileattachment/fileattachment.component.less';
@import 'button.less';
.fileattachment-global-variables() {
                  .button-global-variables();
                  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ButtonBackground: @color-primary;
  @ButtonColor: @color-primary-contrast;
  @BorderBottomStyle: none;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: none;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: none;
  @BorderRightWidth: 1px;
  @BorderTopStyle: none;
  @BorderTopWidth: 1px;

                }

                .zapp-fileattachment  {
                  .fileattachment-global-variables();
                  
                  
                  
                  .fileattachment-status-mixin();
}













