@import '../../@framework/components/progressbar/progressbar.component.less';
.progressbar-global-variables() {
                  .global-variables();
                  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-primary-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;

                }

                .zapp-progressbar  {
                  .progressbar-global-variables();
                  
                  
                  
                  .progressbar-status-mixin();
}

.progressbar-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-default-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;



  .progressbar-status-mixin();
}

.progressbar-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-primary-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;



  .progressbar-status-mixin();
}

.progressbar-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-info-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;



  .progressbar-status-mixin();
}

.progressbar-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-success-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;



  .progressbar-status-mixin();
}

.progressbar-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-warning-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;



  .progressbar-status-mixin();
}

.progressbar-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @color-danger-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;



  .progressbar-status-mixin();
}

.progressbar-size-xs {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @FontSize: @font-xs;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @ForegroundColor: transparent;
  @Height: 5px;



  .progressbar-size-mixin();
}

.progressbar-size-sm {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @FontSize: @font-sm;
  @ForegroundColor: @color-primary-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 10px;



  .progressbar-size-mixin();
}

.progressbar-size-med {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @FontSize: @font-med;
  @ForegroundColor: @color-primary-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 20px;



  .progressbar-size-mixin();
}

.progressbar-size-lg {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @FontSize: @font-lg;
  @ForegroundColor: @color-primary-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 30px;



  .progressbar-size-mixin();
}

.progressbar-size-xl {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @FontSize: @font-xl;
  @ForegroundColor: @color-primary-contrast;
  @ProgressBarBackgroundColor: @input-bg;
  @Shadow: @default-shadow;
  @Height: 50px;



  .progressbar-size-mixin();
}


