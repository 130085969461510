@import '../../@framework/components/label/label.component.less';
.label-global-variables() {
                  .global-variables();
                  @FontSize: @font-med;
  @ForegroundColor: @font-color;

                }

                .zapp-label  {
                  .label-global-variables();
                  
                  
                  
                  .label-status-mixin();
}

.label-status-default {
  .global-variables();
  @ForegroundColor: @color-default;



  .label-status-mixin();
}

.label-status-primary {
  .global-variables();
  @ForegroundColor: @color-primary;



  .label-status-mixin();
}

.label-status-info {
  .global-variables();
  @ForegroundColor: @color-info;



  .label-status-mixin();
}

.label-status-success {
  .global-variables();
  @ForegroundColor: @color-success;



  .label-status-mixin();
}

.label-status-warning {
  .global-variables();
  @ForegroundColor: @color-warning;



  .label-status-mixin();
}

.label-status-danger {
  .global-variables();
  @ForegroundColor: @color-danger;



  .label-status-mixin();
}

.label-size-xs {
  .global-variables();
  @FontSize: @font-xs;



  .label-size-mixin();
}

.label-size-sm {
  .global-variables();
  @FontSize: @font-sm;



  .label-size-mixin();
}

.label-size-med {
  .global-variables();
  @FontSize: @font-med;



  .label-size-mixin();
}

.label-size-lg {
  .global-variables();
  @FontSize: @font-lg;



  .label-size-mixin();
}

.label-size-xl {
  .global-variables();
  @FontSize: @font-xl;



  .label-size-mixin();
}


