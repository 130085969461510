.component-margin (@variables) {
  margin-bottom: @variables[MarginBottom];
  margin-left: @variables[MarginLeft];
  margin-right: @variables[MarginRight];
  margin-top: @variables[MarginTop];
}

.component-padding (@variables) {
  padding-bottom: @variables[PaddingBottom];
  padding-left: @variables[PaddingLeft];
  padding-right: @variables[PaddingRight];
  padding-top: @variables[PaddingTop];
}

.component-dimesions (@variables) {
  // Dimensions
  .component-margin(@variables);
  .component-padding(@variables);
}

.component-margin () {
  margin-bottom: @MarginBottom;
  margin-left: @MarginLeft;
  margin-right: @MarginRight;
  margin-top: @MarginTop;
}

.component-padding () {
  padding-bottom: @PaddingBottom;
  padding-left: @PaddingLeft;
  padding-right: @PaddingRight;
  padding-top: @PaddingTop;
}

.component-dimesions () {
  // Dimensions
  .component-margin();
  .component-padding();
}

.child-margin () {
  margin-left: 5px;
  &:first-child {
    margin-left: 0px !important;
  }
}
