@import '../../@framework/components/iconism/iconism.component.less';
.icon-global-variables() {
                  .global-variables();
                  @FontSize: @font-med;
  @RoundBackgroundColor: @bg-color;
  @Opacity: 0.8;
  @Padding: 0.75rem;
  @RoundForegroundColor: #111111;

                }

                .zapp-icon  {
                  .icon-global-variables();
                  
                  
                  
                  .icon-status-mixin();
}

.icon-status-default {
  .global-variables();
  @ForegroundColor: @color-default;
  @RoundBackgroundColor: @color-default;
  @RoundForegroundColor: @color-default-contrast;



  .icon-status-mixin();
}

.icon-status-primary {
  .global-variables();
  @ForegroundColor: @color-primary;
  @RoundBackgroundColor: @color-primary;
  @RoundForegroundColor: @color-primary-contrast;



  .icon-status-mixin();
}

.icon-status-info {
  .global-variables();
  @ForegroundColor: @color-info;
  @RoundBackgroundColor: @color-info;
  @RoundForegroundColor: @color-info-contrast;



  .icon-status-mixin();
}

.icon-status-success {
  .global-variables();
  @ForegroundColor: @color-success;
  @RoundBackgroundColor: @color-success;
  @RoundForegroundColor: @color-success-contrast;



  .icon-status-mixin();
}

.icon-status-warning {
  .global-variables();
  @ForegroundColor: @color-warning;
  @RoundBackgroundColor: @color-warning;
  @RoundForegroundColor: @color-warning-contrast;



  .icon-status-mixin();
}

.icon-status-danger {
  .global-variables();
  @ForegroundColor: @color-danger;
  @RoundBackgroundColor: @color-danger;
  @RoundForegroundColor: @color-danger-contrast;



  .icon-status-mixin();
}

.icon-size-xs {
  .global-variables();
  @FontSize: @font-xs;



  .icon-size-mixin();
}

.icon-size-sm {
  .global-variables();
  @FontSize: @font-sm;



  .icon-size-mixin();
}

.icon-size-med {
  .global-variables();
  @FontSize: @font-med;



  .icon-size-mixin();
}

.icon-size-lg {
  .global-variables();
  @FontSize: @font-lg;



  .icon-size-mixin();
}

.icon-size-xl {
  .global-variables();
  @FontSize: @font-xl;



  .icon-size-mixin();
}


