@import '../../@framework/components/fieldset/fieldset.component.less';
.panel-global-variables() {
                  .global-variables();
                  @BorderBottomColor: @border-color;
  @BorderLeftColor: @border-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @border-color;
  @BorderTopColor: @border-color;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @MarginBottom: 1rem;

                }

                .zapp-panel  {
                  .panel-global-variables();
                  
                  
                  
                  .panel-status-mixin();
}

.panel-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;



  .panel-status-mixin();
}

.panel-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;



  .panel-status-mixin();
}

.panel-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;



  .panel-status-mixin();
}

.panel-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;



  .panel-status-mixin();
}

.panel-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;



  .panel-status-mixin();
}

.panel-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;



  .panel-status-mixin();
}







.panel-header-global-variables() {
                  .global-variables();
                  @BackgroundColor: @header-bg;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @PaddingBottom: 0.5rem;
  @PaddingLeft: 1rem;
  @PaddingRight: 1rem;
  @PaddingTop: 0.5rem;

                }

                .zapp-panel-header  {
                  .panel-header-global-variables();
                  
                  
                  
                  .panel-header-status-mixin();
}

.panel-header-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderBottomColor: @color-default;
  @ForegroundColor: @color-default-contrast;



  .panel-header-status-mixin();
}

.panel-header-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderBottomColor: @color-primary;
  @ForegroundColor: @color-primary-contrast;



  .panel-header-status-mixin();
}

.panel-header-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderBottomColor: @color-info;
  @ForegroundColor: @color-info-contrast;



  .panel-header-status-mixin();
}

.panel-header-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderBottomColor: @color-success;
  @ForegroundColor: @color-success-contrast;



  .panel-header-status-mixin();
}

.panel-header-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderBottomColor: @color-warning;
  @ForegroundColor: @color-warning-contrast;



  .panel-header-status-mixin();
}

.panel-header-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderBottomColor: @color-danger;
  @ForegroundColor: @color-danger-contrast;



  .panel-header-status-mixin();
}







.panel-body-global-variables() {
                  .global-variables();
                  @BackgroundColor: @containers-color;
  @PaddingBottom: 0.5rem;
  @PaddingLeft: 1rem;
  @PaddingRight: 1rem;
  @PaddingTop: 0.5rem;

                }

                .zapp-panel-body  {
                  .panel-body-global-variables();
                  
                  
                  
                  .panel-body-status-mixin();
}

.panel-body-status-default {
  .global-variables();



  .panel-body-status-mixin();
}

.panel-body-status-primary {
  .global-variables();



  .panel-body-status-mixin();
}

.panel-body-status-info {
  .global-variables();



  .panel-body-status-mixin();
}

.panel-body-status-success {
  .global-variables();



  .panel-body-status-mixin();
}

.panel-body-status-warning {
  .global-variables();



  .panel-body-status-mixin();
}

.panel-body-status-danger {
  .global-variables();



  .panel-body-status-mixin();
}







.panel-footer-global-variables() {
                  .global-variables();
                  @BackgroundColor: @header-bg;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;

                }

                .zapp-panel-footer  {
                  .panel-footer-global-variables();
                  
                  
                  
                  .panel-footer-status-mixin();
}

.panel-footer-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderTopColor: @color-default;
  @ForegroundColor: @color-default-contrast;



  .panel-footer-status-mixin();
}

.panel-footer-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderTopColor: @color-primary;
  @ForegroundColor: @color-primary-contrast;



  .panel-footer-status-mixin();
}

.panel-footer-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderTopColor: @color-info;
  @ForegroundColor: @color-info-contrast;



  .panel-footer-status-mixin();
}

.panel-footer-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderTopColor: @color-success;
  @ForegroundColor: @color-success-contrast;



  .panel-footer-status-mixin();
}

.panel-footer-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderTopColor: @color-warning;
  @ForegroundColor: @color-warning-contrast;



  .panel-footer-status-mixin();
}

.panel-footer-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderTopColor: @color-danger;
  @ForegroundColor: @color-danger-contrast;



  .panel-footer-status-mixin();
}







