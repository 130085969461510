@import '../../@framework/components/calendar/calendar.component.less';
.calendar-global-variables() {
                  .global-variables();
                  @BorderBottomColor: @border-color;
  @BorderLeftColor: @border-color;
  @BorderRightColor: @border-color;
  @BorderTopColor: @border-color;
  @ButtonsBackgroundColor: @color-default;
  @ButtonsColor: @color-default-contrast;
  @ButtonsRadius: @default-radius;
  @DayNumberBackgroundColor: @header-bg;
  @DayNumberColor: @header-color;
  @DayNumberFontSize: @font-med;
  @EventBorderColor: @header-bg;
  @EventBorderRadius: @default-radius;
  @EventColor: @header-bg;
  @EventFontSize: @font-med;
  @EventHoverBackgroundColor: @header-bg;
  @EventHoverColor: @header-color;
  @HeaderColor: @header-color;
  @HeaderFontSize: @font-med;
  @TodayBackgroundColor: @color-default;
  @BackgroundColor: transparent;
  @BorderBottomStyle: solid;
  @BorderBottomWidth: 1px;
  @BorderLeftStyle: solid;
  @BorderLeftWidth: 1px;
  @BorderRightStyle: solid;
  @BorderRightWidth: 1px;
  @BorderTopStyle: solid;
  @BorderTopWidth: 1px;
  @ButtonsMarginLeft: 5px;
  @DayNumberFontWeight: bold;
  @DayNumberHeight: 15px;
  @DayNumberMargin: 5px;
  @DayNumberPadding: 8px;
  @DayNumberRadius: 50%;
  @DayNumberWidth: 15px;
  @EventBackgroundColor: transparent;
  @EventBorderStyle: solid;
  @EventBorderWidth: 1px;
  @EventFontWeight: bold;
  @EventHoverTextDecoration: none;
  @EventIconOffsetTop: -1px;
  @EventIconSize: 10px;
  @EventMarginBottom: 5px;
  @HeaderHeight: 50px;
  @PaddingBottom: 20px;
  @PaddingLeft: 20px;
  @PaddingRight: 20px;
  @PaddingTop: 20px;

                }

                .zapp-calendar  {
                  .calendar-global-variables();
                  
                  
                  
                  .calendar-status-mixin();
}

.calendar-status-default {
  .global-variables();



  .calendar-status-mixin();
}

.calendar-status-primary {
  .global-variables();



  .calendar-status-mixin();
}

.calendar-status-info {
  .global-variables();



  .calendar-status-mixin();
}

.calendar-status-success {
  .global-variables();



  .calendar-status-mixin();
}

.calendar-status-warning {
  .global-variables();



  .calendar-status-mixin();
}

.calendar-status-danger {
  .global-variables();



  .calendar-status-mixin();
}







