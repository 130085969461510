@import '../../@framework/components/passwordtextbox/passwordtextbox.component.less';
.passwordtextbox-global-variables() {
                  .global-variables();
                  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @input-color;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-h;
  @PaddingRight: @input-padding-h;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;

                }

                .zapp-passwordtextbox  {
                  .passwordtextbox-global-variables();
                  
                  
                  
                  .passwordtextbox-status-mixin();
}

.passwordtextbox-status-default {
  .global-variables();
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;



  .passwordtextbox-status-mixin();
}

.passwordtextbox-status-primary {
  .global-variables();
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;



  .passwordtextbox-status-mixin();
}

.passwordtextbox-status-info {
  .global-variables();
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;



  .passwordtextbox-status-mixin();
}

.passwordtextbox-status-success {
  .global-variables();
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;



  .passwordtextbox-status-mixin();
}

.passwordtextbox-status-warning {
  .global-variables();
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;



  .passwordtextbox-status-mixin();
}

.passwordtextbox-status-danger {
  .global-variables();
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;



  .passwordtextbox-status-mixin();
}

.passwordtextbox-size-xs {
  .global-variables();
  @FontSize: @font-xs;



  .passwordtextbox-size-mixin();
}

.passwordtextbox-size-sm {
  .global-variables();
  @FontSize: @font-sm;



  .passwordtextbox-size-mixin();
}

.passwordtextbox-size-med {
  .global-variables();
  @FontSize: @font-med;



  .passwordtextbox-size-mixin();
}

.passwordtextbox-size-lg {
  .global-variables();
  @FontSize: @font-lg;



  .passwordtextbox-size-mixin();
}

.passwordtextbox-size-xl {
  .global-variables();
  @FontSize: @font-xl;



  .passwordtextbox-size-mixin();
}


